<template>
  <div>
    <div class="row">

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Monitoring Rules <button class="btn btn-success btn-sm float-end" data-bs-toggle="modal" data-bs-target="#createMonitoringRuleModal">New Rule</button></h5>

            <table id="example" class="table table-hover" style="width:100%">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Device Type</th>
                <th>Monitor Type</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                <tr class="text-center" v-if="rules.length < 1">
                  <td colspan="5">No rules found.</td>
                </tr>
                <tr v-for="rule in rules" :key="rule.id">
                  <td>{{rule.id}}</td>
                  <td>{{rule.name}}</td>
                  <td>{{rule.device_type}}</td>
                  <td>{{rule.request_type}}</td>
                  <td><button class="btn btn-sm btn-primary" @click="editRule(rule)">Edit Rule</button> <button class="btn btn-sm btn-danger ms-2" @click="deleteRule(rule)">Delete Rule</button></td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>

      <div class="modal fade" id="createMonitoringRuleModal" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Create Monitoring Rule</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <p><strong class="text-warning">Please be warned:</strong> The monitoring system will follow the rules you set explicitly. If you define multiple rules which ping the devices then the monitoring
                  system will complete multiple ping tests on each device the rule matches.</p>
                </div>
                <div class="col-md-12 mb-2">
                  <p>Fields marked <span class="text-danger">*</span> are required</p>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="ruleName" class="form-label">Name <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="ruleName" v-model="rule.name">
                  <div class="invalid-feedback">Please provide a valid name.</div>
                  <div class="valid-feedback">Looks good!</div>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="ruleDeviceType" class="form-label">Device Type <span class="text-danger">*</span></label>
                  <select v-model="rule.device_type" placeholder="- Select a Type -" id="ruleDeviceType" :value="rule.device_type" class="form-control form-select">
                    <option selected disabled>- Select a Type -</option>
                    <option v-for="type in types" :key="type" :value="type">{{type}}</option>
                  </select>
                  <div class="invalid-feedback">Please provide a device type.</div>
                  <div class="valid-feedback">Looks good!</div>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="ruleMonitorType" class="form-label">Monitor Type <span class="text-danger">*</span></label>
                  <select v-model="rule.monitor_type" placeholder="- Select a Type -" id="ruleMonitorType" :value="rule.monitor_type" class="form-control form-select">
                    <option value="PING">Ping</option>
                    <option value="API" disabled>API Request</option>
                  </select>
                  <div class="invalid-feedback">Please provide a monitor type.</div>
                  <div class="valid-feedback">Looks good!</div>
                </div>

                <div v-if="rule.monitor_type === 'PING'">
                  <div class="col-md-12 mb-2">
                    <label for="rulePingTimeout" class="form-label">Timeout <span class="text-danger">*</span> <span class="text-info ms-2" title="How long to wait before declaring a ping has failed (in seconds)"><i class='bx bxs-help-circle' ></i></span></label>
                    <input type="number" min="3" max="30" step="1" class="form-control" id="rulePingTimeout" v-model="rule.timeout">
                    <div class="invalid-feedback">Please provide a valid timeout between 3 and 30 seconds.</div>
                    <div class="valid-feedback">Looks good!</div>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="rulePingMinReplies" class="form-label">Minimum Replies <span class="text-danger">*</span> <span class="text-info ms-2" title="Number of pings to wait for during test."><i class='bx bxs-help-circle' ></i></span></label>
                    <input type="number" min="3" max="10" step="1" class="form-control" id="rulePingMinReplies" v-model="rule.minimumReplies">
                    <div class="invalid-feedback">Please provide a valid minimum reply between 3 and 10.</div>
                    <div class="valid-feedback">Looks good!</div>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="rulePingDeadline" class="form-label">Deadline<span class="text-danger">*</span> <span class="text-info ms-2" title="Maximum time to wait for ping test to complete before moving on."><i class='bx bxs-help-circle' ></i></span></label>
                    <input type="number" min="10" max="120" step="1" class="form-control" id="rulePingDeadline" v-model="rule.deadline">
                    <div class="invalid-feedback">Please provide a valid deadline between 1 and 30 seconds.</div>
                    <div class="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div v-if="rule.monitor_type === 'API'">

                </div>

              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateMonitoringRule'>Close</button>
              <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createRule" text="Save changes"></submit-button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="updateMonitoringRuleModal" tabindex="-1" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Update Monitoring Rule</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <p><strong class="text-warning">Please be warned:</strong> The monitoring system will follow the rules you set explicitly. If you define multiple rules which ping the devices then the monitoring
                    system will complete multiple ping tests on each device the rule matches.</p>
                </div>
                <div class="col-md-12 mb-2">
                  <p>Fields marked <span class="text-danger">*</span> are required</p>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="updateRuleName" class="form-label">Name <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="updateRuleName" v-model="currentRule.name">
                  <div class="invalid-feedback">Please provide a valid name.</div>
                  <div class="valid-feedback">Looks good!</div>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="updateRuleDeviceType" class="form-label">Device Type <span class="text-danger">*</span></label>
                  <select v-model="currentRule.device_type" placeholder="- Select a Type -" id="updateRuleDeviceType" :value="currentRule.device_type" class="form-control form-select">
                    <option selected disabled>- Select a Type -</option>
                    <option v-for="type in types" :key="type" :value="type">{{type}}</option>
                  </select>
                  <div class="invalid-feedback">Please provide a device type.</div>
                  <div class="valid-feedback">Looks good!</div>
                </div>
                <div class="col-md-12 mb-2">
                  <label for="updateRuleMonitorType" class="form-label">Monitor Type <span class="text-danger">*</span></label>
                  <select v-model="currentRule.monitor_type" placeholder="- Select a Type -" id="updateRuleMonitorType" :value="currentRule.monitor_type" class="form-control form-select">
                    <option value="PING">Ping</option>
                    <option value="API" disabled>API Request</option>
                  </select>
                  <div class="invalid-feedback">Please provide a monitor type.</div>
                  <div class="valid-feedback">Looks good!</div>
                </div>

                <div v-if="currentRule.monitor_type === 'PING'">
                  <div class="col-md-12 mb-2">
                    <label for="updateRulePingTimeout" class="form-label">Timeout <span class="text-danger">*</span> <span class="text-info ms-2" title="How long to wait before declaring a ping has failed (in seconds)"><i class='bx bxs-help-circle' ></i></span></label>
                    <input type="number" min="3" max="30" step="1" class="form-control" id="updateRulePingTimeout" v-model="currentRule.timeout">
                    <div class="invalid-feedback">Please provide a valid timeout between 3 and 30 seconds.</div>
                    <div class="valid-feedback">Looks good!</div>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="updateRulePingMinReplies" class="form-label">Minimum Replies <span class="text-danger">*</span> <span class="text-info ms-2" title="Number of pings to wait for during test."><i class='bx bxs-help-circle' ></i></span></label>
                    <input type="number" min="3" max="10" step="1" class="form-control" id="updateRulePingMinReplies" v-model="currentRule.minimumReplies">
                    <div class="invalid-feedback">Please provide a valid minimum reply between 3 and 10.</div>
                    <div class="valid-feedback">Looks good!</div>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="updateRulePingDeadline" class="form-label">Deadline<span class="text-danger">*</span> <span class="text-info ms-2" title="Maximum time to wait for ping test to complete before moving on."><i class='bx bxs-help-circle' ></i></span></label>
                    <input type="number" min="10" max="120" step="1" class="form-control" id="updateRulePingDeadline" v-model="currentRule.deadline">
                    <div class="invalid-feedback">Please provide a valid deadline between 1 and 30 seconds.</div>
                    <div class="valid-feedback">Looks good!</div>
                  </div>
                </div>
                <div v-if="currentRule.monitor_type === 'API'">

                </div>

              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeUpdateMonitoringRule'>Close</button>
              <submit-button type="button" :background="'btn btn-primary'" :clicked="updating" @click="updateRule" text="Save changes"></submit-button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/* global $ */
import axios from "axios";
import SubmitButton from "../../../components/SubmitButton";

export default {
  name: "ClientMonitoringRules",
  components: {
      SubmitButton
  },
  props: {
    client_id: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      rules: [],
      rule: {
        name: null
      },
      creating: false,
      types: [],
      updating: false,
      currentRule: {}
    }
  },
  mounted(){
    this.loadRules();
    this.loadTypes();
  },
  methods: {
    loadRules(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/rules`)
      .then(response => {
        if(response.data.success){
          this.rules = response.data.rules;
        }
      })
      .catch(error => {
        this.$error("Failed to load rules", error);
      })
    },
    loadTypes(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/product/types`)
      .then(response => {
        this.types = response.data.types;
      })
      .catch(error => {
        this.$error("Failed to load device types", error);
      })
    },
    createRule(){

      $('.is-invalid').removeClass('is-invalid');

      if((this.rule.name || "").length < 1){
        document.getElementById('ruleName').classList.add('is-invalid');
      }else{
        document.getElementById('ruleName').classList.add('is-valid');
      }

      if((this.rule.device_type || "").length < 1){
        document.getElementById('ruleDeviceType').classList.add('is-invalid');
      }else{
        document.getElementById('ruleDeviceType').classList.add('is-valid');
      }

      if((this.rule.monitor_type || "").length < 1){
        document.getElementById('ruleMonitorType').classList.add('is-invalid');
      }else{
        document.getElementById('ruleMonitorType').classList.add('is-valid');
      }

      if(this.rule.monitor_type === 'PING'){
        if(this.rule.timeout < 3 || this.rule.timeout > 30 || typeof this.rule.timeout !== 'number'){
          document.getElementById('rulePingTimeout').classList.add('is-invalid');
        }else{
          document.getElementById('rulePingTimeout').classList.add('is-valid');
        }
        if(this.rule.deadline < 1 || this.rule.deadline > 30 || typeof this.rule.deadline !== 'number'){
          document.getElementById('rulePingDeadline').classList.add('is-invalid');
        }else{
          document.getElementById('rulePingDeadline').classList.add('is-valid');
        }
        if(this.rule.minimumReplies < 3 || this.rule.minimumReplies > 10 || typeof this.rule.minimumReplies !== 'number'){
          document.getElementById('rulePingMinReplies').classList.add('is-invalid');
        }else{
          document.getElementById('rulePingMinReplies').classList.add('is-valid');
        }
      }

      if($('.is-invalid').length > 0){
        return;
      }

      let config = {};
      if(this.rule.monitor_type === 'PING'){
        config.timeout = this.rule.timeout;
        config.minimum_replies = this.rule.minimumReplies;
        config.deadline = this.rule.deadline;
      }

      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/rules`, {
        name: this.rule.name,
        device_type: this.rule.device_type,
        monitor_type: this.rule.monitor_type,
        config: JSON.stringify(config)
      })
      .then(() => {
        this.loadRules()
        $('#closeCreateMonitoringRule').click();
      })
      .catch(error => {
        this.$error("Failed to create a new rule", error);
      })
      .finally(() => {
        this.creating = false;
      })

    },
    editRule(rule){
      this.currentRule = {
        id: rule.id,
        name: rule.name,
        monitor_type: rule.request_type,
        device_type: rule.device_type,
        timeout: rule.config.timeout,
        minimumReplies: rule.config.minimum_replies,
        deadline: rule.config.deadline
      };
      $('#updateMonitoringRuleModal').modal('toggle');
    },
    updateRule(){
      let config = {};
      if(this.currentRule.monitor_type === 'PING'){
        config.timeout = this.currentRule.timeout;
        config.minimum_replies = this.currentRule.minimumReplies;
        config.deadline = this.currentRule.deadline;
      }

      this.updating = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/rules/${this.currentRule.id}`, {
        name: this.currentRule.name,
        device_type: this.currentRule.device_type,
        monitor_type: this.currentRule.monitor_type,
        config: JSON.stringify(config)
      })
          .then(() => {
            this.loadRules()
            $('#closeUpdateMonitoringRule').click();
          })
          .catch(error => {
            this.$error("Failed to update a rule", error);
          })
          .finally(() => {
            this.updating = false;
          })
    },
    deleteRule(rule){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/client/${this.client_id}/rules/${rule.id}`)
      .then(response => {
        if(response.data.success){
          this.rules = this.rules.filter((r) => {
            return r.id !== rule.id;
          });
          this.$success("Deleted a rule");
        }else{
          this.$warning("Unknown error when deleting rule");
        }
      })
      .catch(error => {
        this.$error("Failed to delete rule", error);
      })
    }
  }
}
</script>

<style scoped>

</style>